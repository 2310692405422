<template>
  <el-dialog class="operation_dialog bgac_dialog abox dialog_body_top0" v-model="dialogVisible" :title="dialogtitle" :close-on-click-modal="false"
    width="1200px" top="20px">
    <!-- {{ state.Datas }} -->
    <div class="dp-f detailBox">
      <div class="w-580 fs-0 leftbox mt-20">
        <div class="w100 imgbox mb-20">
          <img class="fileimg" :src="state.baseInfo.sysFiles[state.nowpage-1].fileUrl"/>
        </div>
        <getpages class="mb-20" @Emit="getpageEmit" :maxpages="state.maxpages" ref="getpagesRef"></getpages>
      </div>
      <div class="w100 rightbox">
        <div class="headbox pl-30">
           参数填写 
        </div>
        <div class="mt-20 ml-20">
          <formList class="mb-50" :FormDatas="state.FormDatas" @submit="submitEmit" ref="formListRef" />
        </div>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2
          @buttonclick="saveData" v-model:loading="dialogloading"/>
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false"/>
      </span>
    </template>
  </el-dialog>
  <!-- <importDialog ref="importRef" :keys="state.keys" :id="state.id" @Emit="ImportantEmit"></importDialog> -->
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits, computed } from 'vue'
import { getworkTypeData } from "@/utils/server/selectdata.js"
import getpages from "@/components/pagination/getpages.vue"
import importDialog from "./import.vue"
import formList from './formList.vue'
import { httpToken } from "@/utils/request";
import { useStore } from 'vuex';
import { pinyin } from 'pinyin-pro';
import qs from "qs";
import { handleMessage } from "@/utils/server/confirm";
const store= useStore()
const getpagesRef = ref()//分页组件ref
const importRef=ref()
const formListRef = ref()
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogtitle = ref();
const dialogVisible = ref(false)//弹框显示
const emit = defineEmits(['gbEmit','saveEmit','setLoading'])
const state = reactive({
  id:'',
  baseInfo:{
    sysFiles:[]
  },
  nowpage: 1,//当前页码数
	maxpages: 1,//最大页码数
  keys:{},
  showData:[],
  // Datas:[],//批量导入数据
  searchKey:{
    input:'',
  },//搜索框绑定值
  FormDatas: [
    // { label: '企业/组织/商户名称：', type: 1, key: 'companyName', default: '', rules: [{ required: true, message: '请填写企业名称：' }] },
    // { label: '统一社会信用代码：', type: 1, key: 'unCreditCode', default: '', rules: [{ required: true, message: '请填写企业名称：' }] },
    // { label: '法定代表人姓名：', type: 1, key: 'leagalPerson', default: '', rules: [{ required: true, trigger: 'blur'}] },
    // { label: '法定代表人证件号：', type: 1, key: 'leagalID', default: '', rules: [{  required: true }] },
    // { label: '法定代表人手机号：', type: 4, key: 'leagalPhone', default: '', rules: [{ required: true}] },
    // { label: '法定代表人手机号：', type: 1, key: 'leagalPhone', default: '', rules: [{ validator: validPhoneData, trigger: 'blur' }] },
  ],//当前表单数据
  params: [],
})

// const seachClick=()=>{
//   state.showData=state.Datas.filter(function (item) {
//     for (let key in item) {
//       if(item[key].indexOf(state.searchKey.input)!=-1){
//         return item
//       }
//     }
//   });
// }
// const ImportantEmit=(data)=>{
//   state.showData=data
//   state.Datas=data
// }
// console.log('11111111111222',pinyin('测试', { toneType: 'none' }))
// 保存数据
const saveData=()=>{
  unref(formListRef).submitForm()
}
const submitEmit = (flg) => {
  if (!flg) {
    handleMessage("参数填写不正确")
    dialogVisible.value = false
    return
  }
  let formData = unref(formListRef).formModel 
  console.log("formData", formData)
  for (const key in formData) {
    console.log("key", key)
    state.params.forEach(item => {
      if (item.nameKey == key) {
        item.value = formData[key] 
      }
    })

  }
  // if(state.Datas.length==0){
  //   handleMessage('请添加签署方')
  //   return
  // }
  // if(state.Datas.length>=49){
  //   handleMessage('签署方超出限制')
  //   return
  // }
  emit('gbEmit')
  store.commit('initiate/setfileLoading',true)
  dialogloading.value=true
  httpToken({
    method: "post",
    url: "/admin/templateCon/addByTemplate",
    data: qs.stringify({
      // contractFileds : JSON.stringify(state.Datas.map((item)=>{
      //   let data=[]
      //   for(let key in item){
      //     data.push({'fieldKey':key,'field_value':item[key]})
      //   }
      //   return  {'contractFileds':data}
      // })),
      templateId : state.id,
      params: JSON.stringify(state.params),
    }),
  }).then((res) => {
    dialogVisible.value = false
    store.commit('initiate/setfileLoading',false)
    emit('saveEmit', res.data)
    // emit('saveEmit',{
    //   type: 1,//0 默认发起 1模板发起
    //   fileChname: state.baseInfo.templateTitle,//文件中文名
    //   fileUrl: res.data.fileUrl,
    //   id: res.data.id,
    //   signaturePositions: [],//所有签署方位置信息
    //   watermark: [],//所有水印数据
    //   isSetSignature: 0,//签章位置 0 不指定位置 1指定位置
    //   isSetAutograph: 0,//签名位置 0 不指定位置 1指定位置
    //   isSetDate: 0,//日期位置 0 不指定位置 1指定位置
    //   isSetPagInSeal: 0,//骑缝章 0 不需要 1需要
    //   files:res.data.files,//模板文件相关信息
    //   pages: res.data.pages.map(item => {
    //     return {
    //       fileUrl: item.fileUrl,
    //       id: item.id
    //     }
    //   }),
    //   count: state.Datas.length,//数量
    // },state.Datas)
  }).catch(()=>{
    store.commit('initiate/setfileLoading',false)
    dialogloading.value=false
  })
}
const getDetailData=()=>{
  let json = [{ column: "id", type: "eq", value: state.id }];
  httpToken({
    method: "post",
    url: "/admin/templateCon/find",
    data: qs.stringify({
      paramData: JSON.stringify(json),
    }),
  }).then((res) => {
    if(res.data.params){
      state.baseInfo=res.data
      state.maxpages=res.data.sysFiles.length
      state.FormDatas = []
      state.params = res.data.params
      res.data.params.forEach((item,ind)=>{
        let rule = [{ required: true, message: '请填写' + item.name }]
        let param = {
          label: item.name + ":",
          key: item.nameKey,
          type: 1,
          default: "",
          rules: rule
        }
        state.FormDatas.push(param)
      })
      // state.keys['乙方手机号']='乙方手机号'
      dialogVisible.value = true
    }else{
      handleMessage('该模板无动态字段')
    }
    emit('setLoading',false)
  }).catch(()=>{
    emit('setLoading',false)
  })
}
// 分页组件返回新页码
const getpageEmit = ((value) => {
  console.log('value',value)
	state.nowpage = value
})
const resetData=()=>{
  state.keys={}
  state.showData=[]
  state.Datas=[]
}
const show = ((id,title) => {
  resetData()
  dialogloading.value=false
  dialogtitle.value = title
  state.id=id
  getDetailData()
  // dialogVisible.value = true
})
defineExpose({
  show
});
</script>
<style lang="scss" scoped>
@import "@/styles/color/value.scss";
.rightbox{
  width: calc(100% - 580px);
  border-left: 1px solid #E7E7E7;
  .infobox{
    width: 100%;
    overflow:auto;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 5px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 10px!important;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      //background-color: #8c8c8c;
      background-color: rgba(234, 234, 234, 1);
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      border: 0;
    }
    .infoItem{
      width: 100%;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      .item{
        height: 50px;
        line-height: 50px;
        margin-bottom: 8px;
        max-width: 100%;
        div{
          padding: 0 10px;
          flex-shrink: 0;
          width: 200px;
          text-align: center;
          overflow:hidden; //超出的文本隐藏
          text-overflow:ellipsis; //溢出用省略号显示
          white-space:nowrap; //溢出不换行
        border-bottom: 1px solid #EAEAEA;

        }
      }
      .head{
        font-size: 18px;
        font-weight: 600;
        div{
          background: #EAEAEA;
        }
      }
    }
  }
}
.iconfont{
  color: #dcdfe6;
}
.headbox{
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #D8D8D8;
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}
.leftbox{
  padding-right: 20px;
  .imgbox {
    width: 100%;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.11);
    .fileimg {
      width: 100%;
    }
  }
}
::v-deep .el-input{
  height: 42px;
}
.sinputbox{
  ::v-deep .el-input__wrapper{
    box-shadow:none;
    border: 1px solid #dcdfe6;
    border-right: none;
  }
  ::v-deep .el-input-group__append{
    background-color: transparent;
    padding: 0 8px;
  }
}
</style>